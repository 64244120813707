import React from 'react';
import ContactBar from '../components/ContactBar';
import Hero from '../components/Hero';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {Link} from 'gatsby'

const Thanks = () => {

    return (
        <Layout>
            <SEO title="Thanks" />
            <div style={{padding: 100}}>
            <h1>Thank you!</h1>
            <p>We have received your message and a member of the team will get back to you soon! Click <Link to="/">here</Link> to go to the homepage.</p>

            </div>
        </Layout>
    )
}

export default Thanks;